<template>
  <div class="calendar-wrapper">
    <div class="calendar-header">
      <div class="calendar-header-icon">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 77 78">
          <image width="77" height="78" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABOCAYAAABlnZseAAADv0lEQVR4nO2cT6hVRRjAf+9mTkWWoZS5KDMilMhUopCkEolazCKSEmZR5ELo305o4SIRF1Iro00QITjLQJpsZyBaEAWFQRBp/wQXRaSF4Vj5YmSu3Ddv7uX5nfPuu8/7/VZvzpn3nfl+98w5c85795uYnJykKSb4JcBDwLfRup8aB2wZE/wKYDXwRbTut6bRO00DmOAfBn4ADgEnTPAvzkLeYkzwLwDf5/GdNMFvaBqzsTTgDeCm/PM1wFstxGyTN4EFOd4iYFfT2G1Iu61o39JCzDZZWsQq21dMG9LGDpUmQKUJqC45TPBrgOeBjcCdwLUDQi/KN4BezoxGepdYXLT/A/4a0P8f4BfgGLA/WvdV2WGKNBN8uki+Azzb5qjnOR8AL0Xrfp0mzQR/N3A4n1nKVNKZtylad/KyNBN8Wmd9CdyjsvqSFvDro3Vnuou+PQOE/Q2kR49+z1vLgYXFtlF6lFpRtC8Apwf0vxW4obJ9JbAX2D6x8MMDy4CfK4l/A7wKHI3WXex3BBN8ulA+0LstWjcxo3SGgAm+/LC/jtatHZBPGvsjwNvAmmL3v+lDSGfa0xVhae5ujNadHZXkh0W0Lkk+aoJ/FPgcuLfn0MnXM538dqJk9zgK6yXnX3tOfTBJu72y49PhDW+k+awyuOWdytQkz10FarOt08ZjVN+bxIhQ3ggav3VtQ9qRol07peeSY8Wxy/YVs6CFZHbmKf5EXqa8NmLStgL7gPuBT4DXmwZsLC1alxa/rzSNM1tE69JCdkub4fXVkACVJkClCVBpAlSagH53z0Mm+AsjPfLhUL7Gv0Q/aavnebKzik5PASpNgEoT0O+adtco/svUsDHBp7+Z/lEeVs80ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBKg0ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBKg0ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBHTmwdep55Lrasfu5OouJavGwcgMuK/S5fck7Xhlx45cCWVsyfnvqOR/PEk7WNnxOPC+Cf7mcZRmgk814d7L38svOditavUx8FSlw5+5+sH54Q57TknXsQ09FVV7ORyt29z9xkqqcJAKLd1YdEq/+OTV72lGnANeprvkiNadAJ7LZbKU6SQvW6N139G7TovWpSn6GPCjSptCqtK3OVr3UXfjtEKaJvjrgW25kOa6MV0AX8yriv3Au7n2yGUGFjw3wadr3B19ijhdraSpeCpaV69QCvwPI1vL6vWR3VgAAAAASUVORK5CYII="/>
        </svg>
      </div>
      <h2 class="calendar-header-title">
        Choose date
      </h2>
    </div>
    <datepicker v-model="selectedDate" :inline="true">
    </datepicker>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import Datepicker from 'vuejs-datepicker';

export default {
  name: "appointment-select-date",
  components: {
    Datepicker
  },
  data(){
    return {
      selectedDate: this.date
    }
  },
  props: {
    date: {
      type: Date,
      required: true
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.calendar-wrapper {
  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar { /** WebKit */
    display: none;
  }
  overflow-y: scroll;
  margin-right: 20px;
  padding: 40px 30px;
  //max-width: 360px;
  width: 50%;
  border: 1px solid $darkGreen;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  //max-height: 400px;
  .calendar-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .calendar-header-title {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      color: $darkGreen;
      padding: 0 0 8px 12px;
    }
  }
  .vdp-datepicker__calendar {
    border: none !important;
  }
  &.timeslot-wrapper {
    margin: 20px;
    .timeslot-header {
      display: flex;
      align-items: center;
      padding: 8px 0 8px;
      align-self: flex-start;
      font-size: 14px;
      font-weight: 700;
      .timeslot-count {
        font-size: 10px;
        padding-left: 8px;
        color: $lightGreen;
      }
      .day-part {
        padding-left: 8px;
      }
      .day-part-icon {
        width: 25px;
        height: 25px;
      }
    }
    .time-slot-container {
      //max-height: 250px;
      //overflow: auto;
      scrollbar-width: none; /* Firefox 64 */
      -ms-overflow-style: none; /* Internet Explorer 11 */
      &::-webkit-scrollbar { /** WebKit */
        display: none;
      }
      .timeslot-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //margin-left: 27px;
        .time-slot-item {
          color: $textColor;
          font-size: 12px;
          padding: 8px 14px;
          border: 1px solid #eee;
          margin: 3px 5px;
          border-radius: 6px;
          box-shadow: 2px 2px 2px #eee;
          &:hover {
            cursor: pointer;
            background-color: $darkGreen;
            color: $white;
          }
        }
      }
    }
    @media (max-width: 980px) {
      margin-top: 20px !important;
    }
  }
  @media (max-width: 980px) {
    width: 100%;
    margin: 0;
  }
}
</style>
